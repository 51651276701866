import React, {useState, useEffect} from 'react';
import { graphql, Link } from 'gatsby';
import SEO from 'sortd_sections/shared/SEO';
import Article from 'sortd_sections/NewDocs/Article';
import ReadingTime from 'sortd_sections/NewDocs/ReadingTime';
import NavigatorBanner from 'sortd_sections/NewDocs/NavigatorBanner';
import { usePrevAndNext } from '../hooks';
import { capitalize } from '../utils';
import styles from './markdown.module.less';
import { useMedia } from 'react-use';
import {
  EditOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';
import { Layout as AntLayout, Menu, Tooltip, Affix, Select, Card, Avatar, Popover } from 'antd';
import { groupBy } from 'lodash-es';
import Drawer from 'rc-drawer';
import RehypeReact from 'rehype-react';
import Swatch from 'sortd_sections/NewDocs/Swatch';
import {getActiveMenuItem, renderMenu, getPreAndNext} from './utils'
import { navigate } from "@reach/router"  
import sortd from 'assets/images/mini-logo.png';


// https://github.com/antvis/gatsby-theme-antv/issues/114
const parseTableOfContents = (tableOfContents: string) => {
  return tableOfContents.replace(/\/#/g, '#');
};


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}: {
  data: any;
  location: Location;
}) {
  const {pathname} = location;
  //const [prev, next] = usePrevAndNext();
  const { markdownRemark, allMarkdownRemark, site } = data; // data.markdownRemark holds our post data
  if (!markdownRemark) {
    return null;
  }
  const {
    frontmatter,
    htmlAst,
    tableOfContents,
    fields: { slug, readingTime },
  } = markdownRemark;
  const { edges = [] } = allMarkdownRemark;
  const menuList = edges.map(({ node, next, previous }) => {
    const { fields: nodeFields } = node;
    return {
      slug: nodeFields.slug,
      meta: {
        ...node.frontmatter,
        slug: nodeFields.slug,
        filename: nodeFields.slug,
      },
      ...node.frontmatter,
      filename: nodeFields.path,
      next,
      previous,
    };
  });
  const activeMenuItem = getActiveMenuItem(pathname);
  const [product, setProduct] = useState(pathname.split('/')[2])

  const menuItems = renderMenu(menuList, pathname, product);
  //const currentItem = getPreAndNext(menuList, filename);
  //const { next, previous } = currentItem;
 
  const getSideBarOpenKeys = () => {
    const item = menuList.find(({ slug }) => pathname.includes(slug));
    if (item) {
      return [item.type];
    }
    return [];
  };

  const [openKeys, setOpenKeys] = useState<string[]>(
    getSideBarOpenKeys(menuItems)
  );

  const onChangeProduct = (product: string) => {
    console.log(product)
    setProduct(product)
    if(product !== pathname.split('/')[2]){
      navigate(`/docs/${product}`)
    }
  }

    const [expanded, setExpanded] = useState(false)
    const getProduct = () => {
      switch(product){
        case 'change-pro':
          return {name: ' Change Pro', icon: sortd}
        case 'requirements-pro':
          return {name: ' Requirements Pro', icon: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'}
        case 'sortd':
          return {name: ' Sortd', icon: sortd}
        default:
          return {name: ' Sortd', icon: sortd}
      }
    }
  const docSwitcher = (
    <Card
    size='small'
    bordered={false}
      hoverable
      style={{margin: 8}}
      extra={!expanded ? <DownOutlined style={{fontSize: 10}} /> : <UpOutlined style={{fontSize: 10}}/>}
      title={<span><Avatar src={getProduct().icon}/>{getProduct().name}</span>}
      headStyle={!expanded ? {borderBottom: 0} : undefined}
      bodyStyle={!expanded ? {display: 'none', padding: 0} : undefined}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded &&
      <>
    <Card
      size='small'
      bordered={false}
      style={{margin: 8}}
      onClick={() => onChangeProduct('change-pro')}
      title={<span><Avatar src={sortd}/> Change Pro</span>}
      headStyle={{borderBottom: 0}}
      bodyStyle={{display: 'none'}}
    />
    <Card
    size='small'
    bordered={false}
    style={{margin: 8}}
    onClick={() => onChangeProduct('requirements-pro')}
    title={<span><Avatar src='https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'/> Requirements Pro</span>}
    headStyle={{borderBottom: 0}}
    bodyStyle={{display: 'none'}}
  />
      <Card
    size='small'
    bordered={false}
    style={{margin: 8}}
    onClick={() => onChangeProduct('sortd')}
    title={<span><Avatar src={sortd}/> Sortd</span>}
    headStyle={{borderBottom: 0}}
    bodyStyle={{display: 'none'}}
  />
  </>
      }
    </Card>
  )

  const menuChild = (
    <Menu
      inlineIndent={16}
      className="aside-container"
      mode="inline"
      openKeys={openKeys}
      selectedKeys={[activeMenuItem]}
      onOpenChange={(currentOpenKeys) =>
        setOpenKeys(currentOpenKeys as string[])
      }
      style={{height: '100%'}}
    >
      {docSwitcher}
      {menuItems}
    </Menu>
  );


  const isWide = useMedia('(min-width: 767.99px)', true);
  const [drawOpen, setDrawOpen] = useState(false);
  const menuSider = isWide ? (
    <AntLayout.Sider
     width="auto" theme="light" className={styles.sider}>
      {menuChild}
    </AntLayout.Sider>
  ) : (
    <Drawer
      handler={
        drawOpen ? (
          <MenuFoldOutlined className={styles.menuSwitch} />
        ) : (
          <MenuUnfoldOutlined className={styles.menuSwitch} />
        )
      }
      wrapperClassName={styles.menuDrawer}
      onChange={(open) => setDrawOpen(!!open)}
      width={280}
    >
      {menuChild}
    </Drawer>
  );

  const renderAst = new RehypeReact({
    createElement: React.createElement,
    components: {
      swatch: Swatch,
    },
  }).Compiler;

  return (
    <>
      <SEO title={frontmatter.title} />
      <AntLayout
        style={{ background: '#fff' }}
        hasSider
        className={styles.layout}
      >
          {menuSider}
        <Article className={styles.markdown}>
          <Affix offsetTop={88} >
            <div
              className={styles.toc}
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: parseTableOfContents(tableOfContents),
              }}
            />
          </Affix>
          <div className={styles.main}>
            <h1>
              {frontmatter.title}
            </h1>
            <div className={styles.meta}>
              <ReadingTime readingTime={readingTime} />
            </div>
            <div className={styles.content}>{renderAst(htmlAst)}</div>
            <div>

            </div>
          </div>
        </Article>
      </AntLayout>
    </>
  );
}

export const pageQuery = graphql`
  query TemplateDocsMarkdown($slug: String!, $type: String!) {
    site {
      siteMetadata {
        githubUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst      
      tableOfContents
      frontmatter {
        title
        order
        type
      }
      fields {
        path
        slug
        modifiedTime
        avatarList
        readingTime {
          text
          time
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $type } }
      sort: { fields: [frontmatter___order, frontmatter___type], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            order
            type
          }
          fields {
            slug
            path
          }
        }
      }
    }
  }
`;
