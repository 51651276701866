import { IFrontmatterData } from './docs';
import React from 'react';
import { Menu } from 'antd';
import moment from 'moment';
import { Link } from 'gatsby';

const { SubMenu } = Menu;

export interface MenuDataItem extends IFrontmatterData {
  link?: string;
}

export interface IMenuData {
  [key: string]: IMenuData | MenuDataItem[];
}

// matchMedia polyfill for
// https://github.com/WickyNilliams/enquire.js/issues/82

function isNotTopLevel(level: string) {
  return level !== 'topLevel';
}

const shouldBeShown = (slug: string, path: string, product: string) => {
  console.log(slug.split('/'))
  console.log(product)
  if (slug.split('/')[2] === product) {
    return true;
  }
  return false;
};

function getMenuItems(
  moduleData: {
    [key: string]: any;
  }
) {
  const menuMeta = moduleData.map((item: { meta: any }) => item.meta);
  const menuItems: {
    [key: string]: any;
  } = { topLevel: {} };
  menuMeta
    .sort((a: { order: number }, b: { order: number }) => (a.order || 0) - (b.order || 0))
    .forEach((meta: { category: { [x: string]: any }; type: string }) => {
      const category = meta.category || 'topLevel';
      if (!menuItems[category]) {
        menuItems[category] = {};
      }

      const type = meta.type || 'topLevel';
      if (!menuItems[category][type]) {
        menuItems[category][type] = [];
      }

      menuItems[category][type].push(meta);
    });
  return menuItems;
}

export function getActiveMenuItem(pathname: any) {
  if (pathname.endsWith('/')) {
    return pathname.substring(0, pathname.length - 1);
  }
  return pathname;
}

const generateMenuItem = ({ before = null, after = null }, item: MenuDataItem) => {
    if (!item.title) {
      return null;
    }

    const text = [
      <span key="english">{item.title}</span>,
      <span className="chinese" key="chinese">
        {item.subtitle}
      </span>,
    ];

    const { disabled } = item;

    const child = !item.link ? (
      <Link to={item.filename}>
        {before}
        {text}
        {after}
      </Link>
    ) : (
      <a
        href={item.link}
        target="_blank"
        rel="noopener noreferrer"
        className="menu-item-link-outside"
      >
        {before}
        {text} <ExportOutlined />
        {after}
      </a>
    );

    return (
      <Menu.Item key={item.filename} disabled={disabled}>
        {item.important ? <Badge dot={item.important}>{child}</Badge> : child}
      </Menu.Item>
    );
  };

  const generateSubMenuItems = (obj?: IMenuData, footerNavIcons = {}) => {
    if (!obj) return [];
    const topLevel = ((obj.topLevel as MenuDataItem[]) || []).map(item =>
      generateMenuItem({}, item),
    );
    const itemGroups = Object.keys(obj)
      .filter(isNotTopLevel)
      .map((type) => {
        const groupItems = (obj[type] as MenuDataItem[])
          .sort((a, b) => {
            if ('time' in a && 'time' in b) {
              return moment(b.time).valueOf() - moment(a.time).valueOf();
            }
            if ('order' in a && 'order' in b) {
              return a.order - b.order;
            }
            return a.title.charCodeAt(0) - b.title.charCodeAt(0);
          })
          .map(item => generateMenuItem({}, item));
        return (
          <SubMenu title={type} key={type}>
            {groupItems}
          </SubMenu>
        );
      });
    return [...topLevel, ...itemGroups];
  };



  export const getPreAndNext = (menuList: any, filename) => {
    const menuItems: IMenuData = getMenuItems(menuList) || {};


    const list =
      menuItems.length && !menuItems[0].props.children.length
        ? menuItems
        : Object.keys(menuItems).reduce(
            (pre, key) => pre.concat(menuItems[key].props.children),
            [],
          );
    const index = list.findIndex(
      (item: { key: string }) => item.key === filename,
    );

    if (index === -1) {
      return {};
    }
    return {
      previous: list[index - 1],
      next: list[index + 1],
    };
  };

export const renderMenu = (menuList, pathname, product, footerNavIcons = {}) => {
    const menuItems: IMenuData = getMenuItems(menuList.filter((item) => shouldBeShown(item.slug, pathname, product)));
    const topLevel = generateSubMenuItems(menuItems.topLevel as IMenuData, footerNavIcons) || [];
    const result = [...topLevel].filter(({ key }) => key);
    return result;
};